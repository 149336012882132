
// Vue reactivity
import { defineComponent } from 'vue';
import { computed, ref } from 'vue';

// icons
import { arrowBack, arrowBackOutline, arrowForward, close, camera, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonInput,
        IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardTitle, IonCardContent,
        IonFabButton, IonTextarea,
        modalController, } from '@ionic/vue';

// services
import DesignService from '@/services/DesignService';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { usePhotoGallery } from '@/composables/usePhotoGallery';
import { utils } from '@/composables/utils';

export default defineComponent({
  name: 'DesignPreQuestionModal',
  props: ["designId", "targetPetNumber", "petId"],
  components: { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonInput,
                IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardTitle, IonCardContent,
                IonFabButton, IonTextarea, },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const petFeatureRemark = ref("");
    const { openImageModal } = utils();
    const { photos, takePhoto, deletePhoto, } = usePhotoGallery();

    const closeModal = async (petFeatureRemark: any = "") => {
      if (petFeatureRemark != null) {
        const { designId, targetPetNumber, petId } = props;
        store.commit('updateDesign', { designId, updatedObj: { [`pet${targetPetNumber}`]: {
          photos: photos.value, petFeatureRemark
        } } });
        DesignService.upsertDesignPets([
          { id: petId, designId, photos: photos.value, petFeatureRemark },
        ]);
      }
      await modalController.dismiss();
    };

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack, arrowBackOutline, arrowForward, close, camera,

      // variables
      photos, petFeatureRemark,

      // methods
      t, closeModal, openImageModal,
      takePhoto, deletePhoto,
    }
  },
})


// Vue reactivity
import { computed } from 'vue';

// icons
import { arrowBack, share, save, arrowForward, pencil, cart, card, camera, close, } from 'ionicons/icons';

// components
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonIcon, IonInput, IonBackButton,
          IonButtons, IonButton, IonTitle, IonToolbar, IonHeader, IonText, IonCard,
          modalController, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import DesignPostQuestionModal from '@/components/DesignPostQuestionModal.vue';

// services
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'DesignPostQuestionPage',
  components: { IonPage, IonContent, IonGrid, IonRow, IonCol, IonIcon, IonInput, IonBackButton,
                IonButtons, IonButton, IonTitle, IonToolbar, IonHeader, IonText, IonCard,
                LoadingSkeleton, },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    // 1. declare state variables (ref to make them reactive)
    const openPostQuestionModal = async (targetPetNumber: any = 1) => {
      const modal = await modalController.create({
        component: DesignPostQuestionModal,
        componentProps: { designId: "dd273072", targetPetNumber, petId: "7grqthjl" },
      });
      return modal.present();
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack, share, save, arrowForward, pencil, cart, card, camera, close,

      // variables
      openPostQuestionModal,

      // methods
      t,
    }
  }
}
